/* 飞天云动-解决方案-媒体运营 */
<template>
  <div>
    <v-banner
        single-line
        height="500"
        class="banner"
    >
      <v-img
            src="https://h5.ophyer.cn/official_website/banner/LavMedia.png"
            height="100%"
            class="d-flex align-center"
            >
            <v-container color="transparent" class="px-6 max-width-1200 height-per-100 d-flex align-center">
              <div class="flex-grow-1">
                <v-card color="transparent" class="ml-0" flat>
                  <v-card-title class="pa-0 font-size-banner-title family-Bold">
                    <div>
                      <div class="pt-2 color-FFF" style="font-weight: 500; ">媒体运营</div>
                    </div>
                  </v-card-title>
                  <v-card-title class="pa-0 font-size-banner-tip mt-8 color-FFF">
                    全新视觉体验引导用户产生更强烈的消费认同， 助力品牌运营升级
                  </v-card-title>
                </v-card>
              </div>
              <div class="flex-grow-1">
                <v-img src="https://h5.ophyer.cn/official_website/banner/LavMedia_01.png"></v-img>
              </div>
            </v-container>
          </v-img>
    </v-banner>
    <v-container  style="max-width:1200px">
        <div class="public-title" style="margin:80px 0 20px 0">
          <div>
            <span>{{data.module.title}}</span>
          </div>
        </div>
        <div class="carousel-container">
            <div class="c-item-1 box-shadow" v-for='(item,ind) in data.module.itemList' :key='ind' :class="currentHover === (ind+1) ? 'c-item-hover':''" @mouseover="currentHover =(ind+1);">
            <div class="c-item-cover">  
                <v-img :src='item.img' width="248">
                <div class="coverleft" v-show='currentHover===(ind+1)'>
                  <h3 class="font-size-14">{{item.sub}}</h3>
                </div>  
                </v-img>    
            </div>        
            </div>
        </div>
    </v-container>
    <v-card class="con2 wrap" flat>
      <div class="public-title">
        <!-- <p>INTERACTIVE ADVERTISING</p> -->
        <div>
          <span>{{data.module1.title}}</span>
          <span>{{data.module1.subtitle}}</span>
        </div>
      </div>
      <v-tabs v-model="tab" centered color="#0084ff" class="g-tabs pt-6">
        <v-tab v-for="(v, k) in data.module1.itemList" :key="k" @click="currentIndex = 0">
          <b>{{ v.tabTitle }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(v, k) in data.module1.itemList" :key="k" :transition="false">
            <div class="box">
              <transition name="slide-fade" appear>
                <div class="left" v-show="tab == k">
                  <v-card-text class="title" v-text="v.lunTitle" />
                  <v-card-text class="title" v-text="v.lunTitle1" />
                  <v-card-text class="text mt-4" v-text="v.tip" />
                  <div>
                    <div class="tab" v-cloak>
                      <span
                        v-for="(item,index) in v.btns"
                        :key="item.id"
                        :class='currentIndex==index?"active":""'
                        @click="change(index)">
                        {{item.button}}
                      </span>
                    </div>
                  </div>
                </div>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div v-show="tab == k" >
                  <div 
                      v-for="(m,index) in v.btns"
                      :key="index"
                      class="right"
                     v-show="currentIndex==index"
                   >
                    <v-img
                      class="iphone-item d-flex align-center" height="100%"
                      :src="m.imgUrl"
                      contain
                    >
                      <!-- <img :class="m.id == 1? 'item-img-big' : ''"   :src="m.src1" alt="" v-show="m.id==1">
                      <img :class="m.id == 2? 'item-img-big2' : ''"  :src="m.src1" alt="" v-show="m.id==2">
                      <img :class="m.id == 3? 'item-img-big3' : ''"  :src="m.src1" alt="" v-show="m.id==3">
                      <img :class="m.id == 4? 'item-img-big4' : ''"  :src="m.src1" alt="" v-show="m.id==4"> -->
                     </v-img>
                  </div>
                </div>
              </transition>
            </div>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <v-container style="padding-left: 5%;padding-right: 5%;">
      <v-card elevation="0">
        <div class="public-title" style="margin-top:80px">
            <!-- <p>IMPROVE TEACHING QUALITY</p> -->
            <div>
                <span>{{data.module2.title}}</span>
                <!-- <span>{{data.module2.subtitle}}</span> -->
            </div>
        </div>
        <v-container>
          <v-row class="justify-center">
            <v-col cols="9">
              <v-row>
                <v-col cols="3"  v-for="(card,index) in data.module2.itemList" :key="index">
                    <v-card elevation="0">
                      <v-card-title class="font-size-24 font-weight-bold justify-center text-align-center" v-text="card.title"></v-card-title>
                      <div class="d-flex justify-center"><v-img class="flex-grow-0" :src="card.src" width="260" height="146">
                        <template v-slot:placeholder>
                    <v-row class="fill-height ma-0" align="center" justify="center">
                      <v-progress-circular
                        indeterminate
                        size="80"
                        color="red lighten-5"
                      ></v-progress-circular>
                    </v-row>
            </template></v-img></div>
                      <v-card-title class="font-size-18 font-weight-bold justify-center text-align-center" v-text="card.contentTitle"></v-card-title>
                      <v-card-text class="font-size-14 text-align-center"  style="color:#666" v-text="card.content"></v-card-text>
                    </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-container>
   <!--  <v-card class="warps warp5" :flat="true">
     <div class="public-title">
       <div>
          <span>{{data.module3.title}}</span>
          <span>{{data.module3.subtitle}}</span>
          <div style="margin-top: 11px;">{{data.module3.subtitle1}}</div>
       </div>
     </div>
     <div class="con">
       <v-carousel class="carousel-new" height="280" hide-delimiters :reverse="false" show-arrows-on-hover :cycle="true">
         <v-carousel-item
           v-for="(v, k) in data.module3.list"
           :key="k"
         >
           <div class="box" @click="goCaseDetail(k)">
             <img :src="v.img" alt="" />
             <div>
               <p>{{ v.title }}</p>
               <p>{{ v.tip }}</p>
               <p>{{ v.text }}</p>
             </div>
           </div>
         </v-carousel-item>
       </v-carousel>
     </div>
    </v-card> -->
  </div>
</template>

<script>
let _this
import lavData from '@/static/lavData.js'
import qs from 'qs'
export default {
  name: "lavMedia",
  data: () => ({
    imgLoad: false,
    data:lavData.lavMedia,
    tab: null,
    currentHover: 1,
    currentIndex: 0,
  }),
  mounted(){
    _this = this;
    this.bannerLoad = true;
  },
methods: {
    handleLoad: ()=>{
      _this.imgLoad = true;
      console.log("ewf")
    },
    change(index){
      console.log('<img :src="v.img" alt="" />',index)
      this.currentIndex = index;
    },
    goCaseDetail(key) {
      let query = {
        source: 'home',
        key: key,
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    },
}
};
</script>

<style lang="scss" scoped>
.item-img-big {
  width: 176px;
  height: 80px;
  position: absolute;
  top: 102px;
  right:59px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big2{
  width: 143px;
  height: 69px;
  position: absolute;
  top: 296px;
  right:76px;
  animation-delay: 1s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:4s;
}

.item-img-big3{
  width: 195px;
  height: 291px;
  position: absolute;
  top: 97px;
  right:49px;
  border-radius: 10px;
  animation-delay: 3s;
  animation-fill-mode:forwards;
	// 关键帧名称
	-webkit-animation-name:scaleDraw1;
	// 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
	// 动画播放的次数
  -webkit-animation-iteration-count:infinite;
	// 动画所花费的时间
  -webkit-animation-duration:5s;
}

.item-img-big4{
  width: 622px;
  height: 354px;
  position: absolute;
  top: 96px;
  right:-160px;
  animation-delay: 1s;
  z-index: -20;
  // animation-fill-mode:forwards;
  // 关键帧名称
  -webkit-animation-name:move;
  // 动画的速度曲线
  -webkit-animation-timing-function:ease-in-out;
  // 动画播放的次数
  -webkit-animation-iteration-count:infinite;
  // // 动画所花费的时间
  -webkit-animation-duration:10s;
}
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
}
.carousel-container {
    width: 1200px;
    height: 400px;
    position: relative;
    display: flex;
    flex-direction: row;
    animation: section-fade-in 1s ease 0s 1 normal both;
    margin-bottom: 60px;
    .coverleft{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(180deg, rgba(41, 45, 49, 0.8) 0%, rgba(19, 15, 70, 0.7) 100%);
        border-radius: 5px 0px 0px 5px;
        padding-left:16px;
        padding-right: 10px;
        float: left;
        h3{
          color: #FFFFFF;
        }
    }
    .c-item-hover{
        width: 428px;
    }
    .c-item-1{
        margin-right: 10px;
        background-position: 0;
        overflow: hidden;
        position: relative;
        height: 388px;
        border-radius: 15px;
        transition: width .4s;
        transition-timing-function: ease;
    }
    .c-item-1:not(.c-item-hover){
        width: 240px;
        transition: all .4s;
        transition-timing-function: ease;
    }
    .c-item-cover {
        width: 440px;
        height: 380px;
        border-radius: 14px;
        //opacity: .6;
        transition: all .4s;
        display: flex;
        transition-timing-function: ease;
    }
}
.warp2 {
  .box {
    width: 1000px;
    margin: 50px auto;
    display: flex;
    align-items: center;
  }
  .left {
    margin-right: 80px;
    img {
      width: 400px;
      height: 260px;
      border-radius: 30px;
    }
  }
  .right{
    text-align: start;
    //font-family: SourceHanSansCN-Regular;
    margin-right: 110px;
    .v-card__text {
      padding: 0;
      line-height: 30px!important;
    }
    .title {
      font-family: Source Han Sans CN;
      font-size: 24px !important;
      font-weight: 600 !important;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px !important;
      color:#666!important;
      opacity:1;
    }
    .text {
      font-size: 14px;
      line-height: 23px;
    }
  }
}
.con2 {
  .box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .left {
    text-align: left;
    margin-right: 85px;
    .v-card__text {
      padding: 0;
    }
    .title {
      font-size: 24px !important;
      color: #333;
      line-height: 34px;
      width: 280px;
      font-weight: 500;
    }
    .tip {
      margin: 12px 0 35px;
      font-size: 16px;
      color: #666666;
    }
    .text {
      font-size: 16px;
      width: 366px;
      line-height: 26px;
    }
  }
  .right{
    width: 293px;
    height: 519px;
    position: relative;
  }

}
.tab {
  height: 120px;
  span{
    margin: 50px 10px 0px 0px;
    box-sizing: border-box;
    float: left;
    list-style: none;
    cursor: pointer;
    text-align: center;
    width: 120px;
    height: 40px;
    background: #e0ebef;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;color: #666666;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
  .active{
    width: 120px;
    height: 40px;
    background: #3288FF;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;
    line-height: 40px;
    text-align: center;
    cursor: pointer;
  }
}
.ulStyle{
  li{
    list-style:disc;
    line-height:26px;
    color: #666;
  }
}
.warp5 {
  width: 100%;
  padding-bottom: 50px;
  .v-responsive {
    overflow: auto;
  }
  .con {
    max-width: 1200px;
    margin: 0px auto 10px;
    text-align: start;
  }
  .box {
    padding: 30px 180px;
    height: 240px;
    margin-top: 25px;
    cursor: pointer;
    background: #f5f8fd;
    // background: skyblue;
    border-radius: 30px;
    img {
      position: absolute;
      top: 0px;
      left: 170px;
      width: 280px;
      height: 280px;
      border-radius: 30px;
    }
    div {
      padding-left: 310px;
      p:nth-of-type(1) {
        font-size: 24px;
        font-weight: bold;
        color: #333333;
      }
      p:nth-of-type(2) {
        margin: 12px 0 26px;
        font-size: 16px;
        white-space:nowrap;
        color: #333333;
      }
      p:nth-of-type(3) {
        font-size: 13px;
        color: #666;
        line-height: 24px;
      }
    }
  }
  
  
}
</style>